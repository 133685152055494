<template>
<div>
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <validation-observer ref="VFormCatatanPersalinan">
      <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Form Catatan Persalinan</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Tanggal Pengkajian</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                    </div>
                    <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.kajian_date" @input="updateRow(row)">
                    </datepicker>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Jam</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                    </div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.kajian_time" @change="updateRow(row)">
                    </vue-timepicker>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Apakah pasien mengalami KPD?</label>
                  <b-form-radio-group :options="Config.mr.yesNoOptV2"
                      v-model="row['kpd']" />
                </div>
              </div>
              
              <div class="col-md-3" v-if="row['kpd'] == 'Y'">
                <div class="form-group">
                  <Uploader v-model="row.kpd_file" isDocument type="docimage" />
          
                  <VValidate name="File KPD" 
                  v-model="row.kpd_file"                  
                  :rules="{required: 1}" />
                </div>
              </div>
            </div>

            <validation-observer ref="VFormCatatanBBL">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Catatan BBL {{(catatanBBL||[]).length ? '#1':''}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="" for="">Tanggal Lahir <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                            </div>
                            <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.dob" @input="updateRow(row)">
                            </datepicker>
                          </div>
                          <VValidate 
                            name="Tanggal Lahir" 
                            v-model="row.dob" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="" for="">Jam Lahir <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                            </div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.tob" @change="updateRow(row)">
                            </vue-timepicker>
                          </div>
                          <VValidate 
                            name="Jam Lahir" 
                            v-model="row.tob" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Bayi Lahir <span class="text-danger">*</span></label>
                          <b-form-radio-group :options="mrBayiLahir" text-field="label" v-model="row.lahir" @change="updateRow(row);changeBayiLahir()" class="mt-1 mb-1">
                          </b-form-radio-group>
                          <VValidate 
                            name="Bayi Lahir" 
                            v-model="row.lahir" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      
                      <div class="col-md-4 mb-3" v-if="row.lahir == 'MATI'">
                        <label for="">Sebab Kematian</label>
                        <b-form-textarea type="text" placeholder="Sebab kematian" v-model="row.bayi_lahir_mati_note" @change="updateRow(row)" />
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="JenisKelamin">Jenis Kelamin <span class="text-danger">*</span></label>
                          <v-select :reduce="v => v.value" label="text" :options="mrGender" v-model="row.gender" @input="updateRow(row)" :clearable="true" />
                          
                          <VValidate 
                            name="Jenis Kelamin" 
                            v-model="row.gender" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiBeratBadan">Berat Badan</label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiBeratBadan" placeholder="e.g. 3.97" :formatter="Formatter.decimalNumber" v-model="row.berat_badan" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">gram</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiPanjang">Panjang Badan </label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="row.panjang_badan" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="LingkarKepala">Lingkar Kepala</label>
                          <div class="input-group">
                            <b-form-input type="text" id="LingkarKepala" placeholder="" :formatter="Formatter.decimalNumber" v-model="row.lingkar_kepala" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiPanjang">Lingkar Dada </label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="row.lingkar_dada" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Kelainan Konginetal</label>

                          <div class="d-block">
                            <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="row.kelainan_konginetal" @change="updateRow(row)" class="mt-1 mb-1">
                            </b-form-radio-group>
                            <div class="mt-2" v-if="row.kelainan_konginetal == 'Y'">
                              <b-form-input type="text" placeholder="sebutkan" v-model="row.kelainan_konginetal_note" @change="updateRow(row)" />
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Untuk kelainan Jelek, Lahir Hidup kemudian meninggal</label>
                          <div class="d-block">
                            <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="row.kelainan_jelek" @change="updateRow(row)" class="mt-1 mb-2">
                            </b-form-radio-group>

                            <template v-if="row.kelainan_jelek == 'Y'">                          
                              <div class="mb-1">
                                <b-form-input v-model="row.kelainan_jelek_time" @change="updateRow(row)" :formatter="Formatter.number" />
                              </div>
                              <v-select :options="mrKelainanJelekTimeType" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 200px;" v-model="row.kelainan_jelek_time_type" />
                            </template>
                          </div>
                        </div>
                      </div>

                      <template v-if="row.lahir == 'HIDUP'">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Nama Bayi <span class="text-danger">*</span></label>
                          <b-form-input type="text" placeholder="e.g. Kusuma" v-model="row.nama_bayi"/>
                          <VValidate 
                            name="Nama Bayi" 
                            v-model="row.nama_bayi" 
                            :rules="{required: 1, min: 3, max:256}"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Dokter DPJP <span class="text-danger">*</span></label>
                          <v-select :disabled="row.is_inserted == 'Y'" @input="updateRow(row)" :reduce="v => v.value" label="text" :options="mDokter" v-model="row.dokter" :clearable="true" />
                          <VValidate 
                            name="Dokter DPJP" 
                            v-model="row.dokter" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Jenis Rawat Inap <span class="text-danger">*</span></label>
                          <v-select :disabled="row.is_inserted == 'Y'" @input="updateRow(row)" :reduce="v => v.value" label="text" :options="jenisRawatInap" v-model="row.jenis_ranap" :clearable="true"/>
                          <VValidate 
                            name="Jenis Rawat Inap" 
                            v-model="row.jenis_ranap" 
                            :rules="{required: 1}"
                          />
                          
                        </div>
                      </div>
                      </template>

                      <div class="col-md-3" v-if="Object.keys(row||{}).length">
                        <div class="form-group">
                          <label for="bayiPanjang">Bayi Lahir Kembar </label>
                          <div class="input-group">
                            <b-form-radio-group :disabled="row.is_lock_bayi_kembar == 'Y'?true:false" :options="Config.mr.yesNoOptV2" text-field="text" v-model="row.is_bayi_kembar" @change="updateRow(row);changeInputKembar($event)" class="radio-inline mt-1 mb-1">
                            </b-form-radio-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" v-if="Object.keys(row||{}).length && row.lahir == 'HIDUP'">
                        <div class="d-block float-right text-right">
                        
                        <button type="button" v-if="row.is_inserted == 'Y'" class="btn btn-outline-warning-2 mt-3 mr-2" @click="updateData()">
                        Update Data Pasien
                        </button>
                        
                        <button type="button" class="btn btn-outline-success mt-3" @click="addReservasi()">
                        {{row.is_inserted == 'Y' ? 'Kirimkan ulang data Pasien' : 'Kirimkan data Pasien' }}

                        </button>
                          <small class="text-info d-flex text-right mt-1" 
                          v-if="row.inserted_date && row.is_inserted == 'Y'"
                          > *Terkirim pada : 
                            <span>{{row.inserted_date|moment("DD MMMM YYYY, HH:mm")}}
                            <span v-if="row.inserted_by">, Oleh {{row.inserted_by||"-"}}</span>
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </validation-observer>
            
            <template v-if="row.is_bayi_kembar == 'Y'">
            <validation-observer ref="VFormCatatanBBLKembar">
            <div class="row">
              <div class="col-md-12" v-for="(v,k) in (catatanBBL||[])" :key="k">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Catatan BBL #{{k+2}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="" for="">Tanggal Lahir <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                            </div>
                            <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.arancb_tanggal_lahir">
                            </datepicker>
                            
                          </div>
                          
                          <VValidate 
                            message="Tanggal Lahir" 
                            :name="'Tanggal Lahir '+(k+1)"
                            v-model="v.arancb_tanggal_lahir" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="" for="">Jam Lahir <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                            </div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.arancb_jam_lahir">
                            </vue-timepicker>
                          </div>
                          
                          <VValidate 
                            message="Jam Lahir" 
                            :name="'Jam Lahir '+(k+1)"
                            v-model="v.arancb_jam_lahir" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Bayi Lahir <span class="text-danger">*</span></label>
                          <b-form-radio-group :options="mrBayiLahir" text-field="label" v-model="v.arancb_bayi_lahir" class="mt-1 mb-1">
                          </b-form-radio-group>
                          
                          <VValidate 
                            message="Bayi Lahir" 
                            :name="'Bayi Lahir '+(k+1)"
                            v-model="v.arancb_bayi_lahir" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      
                      <div class="col-md-4 mb-3" v-if="v.arancb_bayi_lahir == 'MATI'">
                        <label for="">Sebab Kematian</label>
                        <b-form-textarea type="text" placeholder="Sebab kematian" v-model="v.arancb_sebab_kematian" />
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="JenisKelamin">Jenis Kelamin <span class="text-danger">*</span></label>
                          <v-select :reduce="v => v.value" label="text" :options="mrGender" v-model="v.arancb_jenis_kelamin" :clearable="true" />
                            
                          <VValidate 
                            message="Jenis Kelamin" 
                            :name="'Jenis Kelamin '+(k+1)"
                            v-model="v.arancb_jenis_kelamin" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiBeratBadan">Berat Badan</label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiBeratBadan" placeholder="e.g. 3.97" :formatter="Formatter.decimalNumber" v-model="v.arancb_bb"/>
                            <div class="input-group-append"><span class="input-group-text">gram</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiPanjang">Panjang Badan </label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="v.arancb_tb" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="LingkarKepala">Lingkar Kepala</label>
                          <div class="input-group">
                            <b-form-input type="text" id="LingkarKepala" placeholder="" :formatter="Formatter.decimalNumber" v-model="v.arancb_lingkar_kepala" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="bayiPanjang">Lingkar Dada </label>
                          <div class="input-group">
                            <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="v.arancb_lingkar_dada"/>
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Kelainan Konginetal</label>

                          <div class="d-block">
                            <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="v.arancb_kelainan_konginetal" class="mt-1 mb-1">
                            </b-form-radio-group>
                            <div class="mt-2" v-if="v.arancb_kelainan_konginetal == 'Y'">
                              <b-form-input type="text" placeholder="sebutkan" v-model="v.arancb_kelainan_konginetal_text"/>
                            </div>
                          </div>

                        </div>
                      </div>
                      

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Untuk kelainan Jelek, Lahir Hidup kemudian meninggal</label>
                          <div class="d-block">
                            <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="v.arancb_kelainan_jelek" class="mt-1 mb-1">
                            </b-form-radio-group>

                            <template v-if="v.arancb_kelainan_jelek == 'Y'">                          
                              <div class="mt-2 mb-1">
                                <b-form-input v-model="v.arancb_kelainan_jelek_text" :formatter="Formatter.number" />
                              </div>
                              <v-select :options="mrKelainanJelekTimeType" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 200px;" v-model="v.arancb_kelainan_jelek_text_2" />
                            </template>
                          </div>
                        </div>
                      </div>
                      

                      <template v-if="v.arancb_bayi_lahir == 'HIDUP'">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Nama Bayi <span class="text-danger">*</span></label>
                          <b-form-input type="text" placeholder="e.g. Kusuma" v-model="v.arancb_nama_bayi"/>
                          <VValidate 
                            name="Nama Bayi" 
                            v-model="v.arancb_nama_bayi" 
                            :rules="{required: 1, min: 3, max:256}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Dokter DPJP <span class="text-danger">*</span></label>
                          <v-select :disabled="v.arancb_is_inserted == 'Y'?true:false" :reduce="v => v.value" label="text" :options="mDokter" v-model="v.arancb_dokter" :clearable="true" />
                          <VValidate 
                            message="Dokter DPJP" 
                            :name="'Dokter DPJP '+(k+1)"
                            v-model="v.arancb_dokter" 
                            :rules="{required: 1}"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Jenis Rawat Inap <span class="text-danger">*</span></label>
                          <v-select :disabled="v.arancb_is_inserted == 'Y'?true:false" :reduce="v => v.value" label="text" :options="jenisRawatInap" v-model="v.arancb_jenis_ranap" :clearable="true"/>
                          <VValidate 
                            message="Jenis Rawat Inap" 
                            :name="'Jenis Rawat Inap '+(k+1)"
                            v-model="v.arancb_jenis_ranap" 
                            :rules="{required: 1}"
                          />
                          
                        </div>
                      </div>
                      </template>

                      <div class="col-md-12">
                        <!-- v-if="v.arancb_is_inserted != 'Y'" -->
                        <div class="d-block float-right text-right">
                          <button v-if="v.arancb_is_inserted != 'Y'" type="button" class="btn btn-outline-danger mt-3 mr-2" @click="deleteCatatanBBL(v,k)">
                            Hapus Catatan BBL</button>
                          
                          <button type="button" v-if="v.arancb_is_inserted == 'Y'" class="btn btn-outline-warning-2 mt-3 mr-2" @click="updateDataCatatanBBL(v,k)">
                          Update Data Pasien
                          </button>
                          
                          <button type="button" v-if="v.arancb_bayi_lahir == 'HIDUP'" class="btn btn-outline-success mt-3" @click="addReservasiKembar(v,k)">
                            {{v.arancb_is_inserted == 'Y' ? 'Kirimkan ulang data Pasien' : 'Kirimkan data Pasien' }}                          
                          </button>
                    
                          <small class="text-info d-flex text-right mt-1" 
                          v-if="v.arancb_inserted_date && v.arancb_is_inserted == 'Y'"
                          > *Terkirim pada : 
                            <span>{{v.arancb_inserted_date|moment("DD MMMM YYYY, HH:mm")}}</span><span v-if="v.arancb_inserted_by">, Oleh {{v.arancb_inserted_by||"-"}}</span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center mb-3">
                <button type="button" class="btn btn-outline-primary" @click="addCatatanBBL()">Tambah Catatan BBL</button>
              </div>
            </div>
            </validation-observer>
            </template>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Keadaan ibu Paska Persalinan </h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group">
                          <label>Keadaan Umum</label>
                          <b-form-input type="text" @change="updateRow(row)" v-model="row.ki_keadaan_umum" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Tekanan Darah</label>
                          <div class="input-group">
                            <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="row.ki_sistolik" :formatter="Formatter.number" />
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                            <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="row.ki_diastolik" :formatter="Formatter.number" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                          </div>

                          <VValidate name="Tekanan Darah" :value="row.ki_sistolik && row.ki_diastolik" :rules="mrValidation.ki_sistolik" />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Nadi</label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.nadi" :formatter="Formatter.number" />

                            <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            <div class="input-group-append">
                              <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="row.nadi_type" />
                            </div>
                          </div>

                          <VValidate name="Nadi" :value="row.nadi && row.nadi_type" :rules="mrValidation.nadi" />
                        </div>
                      </div>                      
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Suhu Badan</label>
                          <div class="form-row">
                            <div class="col-md-12">
                              <div class="input-group">
                                <b-form-input 
                                  type="text" 
                                  v-model="row.suhu_badan" 
                                  :formatter="Formatter.decimalNumber" 
                                  @change="updateRow(row)" 
                                />
                                <div class="input-group-append"><span class="input-group-text">Celcius</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Uterus</label>
                          <div class="input-group">
                            <v-select :options="mrUterus" v-model="row.uterus" @input="updateRow(row)" style="width: 100%;" />
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">Lama Kala I</label>
                              <div class="input-group">
                                <b-form-input v-model="row.lama_kala_1_jam" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                <b-form-input v-model="row.lama_kala_1_menit" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">menit</span></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">Lama Kala II</label>
                              <div class="input-group">
                                <b-form-input v-model="row.lama_kala_2_jam" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                <b-form-input v-model="row.lama_kala_2_menit" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">menit</span></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">Lama Kala III</label>
                              <div class="input-group">
                                <b-form-input v-model="row.lama_kala_3_jam" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                <b-form-input v-model="row.lama_kala_3_menit" :formatter="Formatter.number" type="text" class="form-control" @change="updateRow(row)" />
                                <div class="input-group-append"><span class="input-group-text">menit</span></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">Lama Persalinan</label>
                              <div class="input-group">
                                <b-form-input v-model="row.lama_persalinan_jam" :formatter="Formatter.number" type="text" class="form-control" readonly />
                                <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                <b-form-input v-model="row.lama_persalinan_menit" :formatter="Formatter.number" type="text" class="form-control" readonly />
                                <div class="input-group-append"><span class="input-group-text">menit</span></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div>
                              <label>Komplikasi Persalinan<strong class="text-danger">*</strong></label>
                              <b-form-input :formatter="Formatter.normalText" v-model="row.komplikasi_persalinan"  type="text" class="form-control" @change="updateRow(row)" />
                              <VValidate 
                                name="Komplikasi Persalinan" 
                                v-model="row.komplikasi_persalinan" 
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Ketuban</label>
                              <b-form-input :formatter="Formatter.normalText" v-model="row.ketuban"
                                type="text" class="form-control" @change="updateRow(row)" />
                            </div>
                          </div>                          

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Perdarahan Kala III<strong class="text-danger">*</strong></label>

                              <div class="input-group">
                                <b-form-input type="text" placeholder="Perdarahan Kala III" @change="updateRow(row)" v-model="row.perdarahan_kala_3" :formatter="Formatter.number" />
                                <div class="input-group-append"><span class="input-group-text">cc</span></div>
                              </div>

                              <VValidate
                                name="Perdarahan Kala III"
                                v-model="row.perdarahan_kala_3"
                                :rules="mrValidation.perdarahan_kala_3"
                              />
                            </div>
                          </div>
                          
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Perdarahan Kala IV<strong class="text-danger">*</strong></label>

                              <div class="input-group">
                                <b-form-input type="text" placeholder="Perdarahan Kala IV" @change="updateRow(row)" v-model="row.perdarahan_kala_4" :formatter="Formatter.number" />
                                <div class="input-group-append"><span class="input-group-text">cc</span></div>
                              </div>

                              <VValidate
                                name="Perdarahan Kala IV"
                                v-model="row.perdarahan_kala_4"
                                :rules="mrValidation.perdarahan_kala_4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Kondisi Bayi <strong class="text-danger">*</strong></label>
                          <b-form-radio-group :options="Config.mr.sehatTidakSehat" text-field="text" v-model="row.p_kondisi_bayi" @change="updateRow(row)" class="custom-block mt-1 mb-1">
                          </b-form-radio-group>
                          
                          <VValidate
                            name="Kondisi Bayi"
                            v-model="row.p_kondisi_bayi"
                            :rules="mrValidation.p_kondisi_bayi"
                          />
                        </div>
                      </div>
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Placenta</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="bayiWarnaKulit">Bentuk <strong class="text-danger">*</strong></label>
                          <b-form-input type="text" v-model="row.p_bentuk" placeholder="Bentuk" @change="updateRow(row)" />

                          <VValidate
                            name="Bentuk"
                            v-model="row.p_bentuk"
                            :rules="mrValidation.p_bentuk"
                          />
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="bayiWarnaKulit">Tali Pusat<strong class="text-danger">*</strong></label>
                          <b-form-input type="text" v-model="row.p_tali_pusat" placeholder="Tali Pusat" @change="updateRow(row)" />

                          <VValidate
                            name="Tali Pusat"
                            v-model="row.p_tali_pusat"
                            :rules="mrValidation.p_tali_pusat"
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="bayiWarnaKulit">Kulit Ketuban<strong class="text-danger">*</strong></label>
                          <v-select :options="mrKulitKetuban" v-model="row.p_kulit_ketuban" @input="updateRow(row)" />

                          <VValidate
                            name="Kulit Ketuban"
                            v-model="row.p_kulit_ketuban"
                            :rules="mrValidation.p_kulit_ketuban"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="text-right">
              <button type="button" class="btn" @click="$router.back()">Kembali</button>
              <button type="button" class="btn btn-outline-primary mr-2" @click="doSave()">Simpan Sementara</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

import Gen from '@/libs/Gen'

import GlobalVue from '@/libs/Global'
const moment = require('moment')

const _ = global._

export default {
  components: {
    PatientInfo,
    Datepicker,
    VueTimepicker
  },

  extends: GlobalVue,

  data() {
    return {
      loading: {
        patientInfo: false
      },

      patientData: {},

      mrBayiLahir: [],
      mrKelainanJelekTimeType: [],
      mrUterus: [],
      mrKulitKetuban: [],
      dataLaporan: [{}],
      mrValidation: {},

      row: {},

      formData: {
        discharge_planning: [],
        is_discharge: false
      },

      catatanBBL: [],
      mDokter: [],
      jenisRawatInap: [
        {
          text: "Perinatologi",
          value: 2
        },
        {
          text: "Kritis",
          value: 4
        },
      ],
      mrGender: [
        {
          text: 'Laki-Laki',
          value: 'P'
        },
        {
          text: 'Perempuan',
          value: 'W'
        },
        {
          text: 'Tidak Diketahui',
          value: 'TK'
        },
      ]
    }
  },

  mounted() {
    this.getPatientInfo()
    this.getDataForm()

    this.setSignature()
  },

  methods: {
    doSave(){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      setTimeout(() => { 
        this.$swal.close()
        this.$swal({
          title: "Berhasil menyimpan data",
          icon: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(() => {
          this.$router.go(-1)  
        })
      }, 700)
    },
    setSignature(signature) {
      setTimeout(() => {
        if (signature) this.$refs['signature'].fromDataURL(signature)
      }, 1000)
    },
    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.row[field] = data
      }

      this.updateRow(this.row)
    },

    addLaporan() {
      this.dataLaporan.push({})
      this.updateRow(this.dataLaporan[this.dataLaporan.length - 1])
    },

    removeLaporan(key) {
      this.dataLaporan.splice(key, 1)
    },
    updateRow(data) {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: !data.id ? "add-catatan-persalinan" : "update-catatan-persalinan",
            id_registrasi: this.$route.params.pageSlug,
            ...data
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this.loading, 'patientInfo', false)
        if (!data.id) {
          this.$set(data, 'id', resp.data.id)
        }
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getDataForm() {
      this.loadingOverlay = true
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(resp => {
        const {
          row,
          mrKelainanJelekTimeType,
          mrBayiLahir,
          mrKulitKetuban,
          mrUterus,
          mrValidation,
          catatanBBL,
          mDokter
        } = resp.data
        this.loadingOverlay = false
        this.$set(this, 'row', row)
        this.$set(this, 'mrKelainanJelekTimeType', mrKelainanJelekTimeType)
        this.$set(this, 'mrBayiLahir', mrBayiLahir)
        this.$set(this, 'mrUterus', mrUterus)
        this.$set(this, 'mrKulitKetuban', mrKulitKetuban)
        this.$set(this, 'catatanBBL', catatanBBL)     
        this.$set(this, 'mDokter', mDokter)     
        this.$set(this, 'mrValidation', mrValidation)
      })
    },

    doSubmit() {
      if(this.row.is_bayi_kembar == 'Y' && !(this.catatanBBL||[]).length){
        return this.$swal({
          title: "Bayi Lahir Kembar Diharuskan mengisi Catatan BBL Lebih dari satu",
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
      this.$refs['VFormCatatanPersalinan'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              type: "submit-kajian-bbl",
              id_registrasi: this.$route.params.pageSlug,
              // p_kondisi_bayi: this.row.p_kondisi_bayi,
              // bayi_hidup : this.row.lahir,
              // dokter: this.row.dokter,
              // jenis_ranap: this.row.jenis_ranap,
              // gender: this.row.gender,
              // berat_badan: this.row.berat_badan,
              // panjang_badan: this.row.panjang_badan,
              // lingkar_kepala: this.row.lingkar_kepala,
              // lingkar_dada: this.row.lingkar_dada,
              // nama_bayi: this.row.nama_bayi,

              // dataBayi: (this.catatanBBL||[]),              
            }
          },
          "POST"
        ).then(resp => {
          this.$swal.close()
          this.$swal({
            title: "Berhasil mengisi laporan persalinan",
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          }).then(result => {
            if (result.value) {
              this.$router.back()
            }
          })
        })
      })
    },

    changeInputKembar(e){
      if(e == 'Y'){
        this.addCatatanBBL()  
      }else{
        this.deleteAllCatatanBBL()
      } 
    },

    addCatatanBBL(){
      let newTime = moment(this.row.tob, "HH:mm").format("HH:mm:ss")
      console.log(newTime)
      let data = {
        arancb_aranr_id: this.$route.params.pageSlug,
        arancb_tanggal_lahir: this.row.dob,
        arancb_jam_lahir: newTime,
        arancb_dokter: null,
        arancb_jenis_ranap: null,
        arancb_bb: null,
        arancb_tb: null,
        arancb_lingkar_kepala: null,
        arancb_lingkar_dada: null,
        arancb_nama_bayi: this.patientData.ap_fullname,
        arancb_inserted_date: null,
        arancb_inserted_by: null,
        type: 'add-catatan-bbl',
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.catatanBBL.push(res.data.data)
      }) 
    },
    deleteCatatanBBL(row,k){
      let data = row
      data.type = 'delete-catatan-bbl'
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.catatanBBL.splice(k,1)
      }) 
    },
    deleteAllCatatanBBL(){
      let data = {
        type: 'delete-all-catatan-bbl',
        id_reg: this.$route.params.pageSlug
     }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.catatanBBL = []
      }) 
    },
    autoSaveCatatanBBL: _.debounce(function (data) {
      let dataPost = {
          type: 'auto-save-catatan-bbl',
          data: data
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: dataPost
          },
          "POST"
      )
    }, 1000),

    addReservasi(){
      this.$refs['VFormCatatanBBL'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin untuk mengirim data pasien?',
            text: 'Data BBL akan dikirim ke Admisi dan Dokter Terkait. Jenis ranap tidak bisa diubah setelah data dikirim',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: {
                    type: "add-reservasi-kajian-bbl",
                    id_registrasi: this.$route.params.pageSlug,
                    p_kondisi_bayi: this.row.p_kondisi_bayi,
                    bayi_hidup : this.row.lahir,
                    dokter: this.row.dokter,
                    jenis_ranap: this.row.jenis_ranap,
                    gender: this.row.gender,
                    berat_badan: this.row.berat_badan,
                    panjang_badan: this.row.panjang_badan,
                    lingkar_kepala: this.row.lingkar_kepala,
                    lingkar_dada: this.row.lingkar_dada,
                    nama_bayi: this.row.nama_bayi,
                  }
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mendaftarkan pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.getDataForm()
                  }
                })
              })
            }
          })
        }
      })
      
      
    },
    changeBayiLahir(){
      this.row.nama_bayi = this.patientData.ap_fullname
    },
    addReservasiKembar(row,k){
      this.$refs['VFormCatatanBBLKembar'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin untuk mengirim data pasien?',
            text: 'Data BBL akan dikirim ke Admisi dan Dokter Terkait. Jenis ranap tidak bisa diubah setelah data dikirim',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              let data = row
              data.type = "add-reservasi-kajian-bbl-kembar"
              data.id_registrasi = this.$route.params.pageSlug
              data.p_kondisi_bayi = this.row.p_kondisi_bayi

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: data      
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mendaftarkan pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.getDataForm()
                  }
                }).catch(err=>{ 
                  this.$swal.close()
                  this.loadingOverlay = false 
                  if(err){
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response.data.title
                      err.message = err.response.data.message
                      err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })    
              })
            }
          })
        }
      })
      
    },

    updateDataCatatanBBL(row,k){
      this.$refs['VFormCatatanBBLKembar'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda yakin untuk mengubah data pasien??',
            text: 'Data BBL di Dokter akan berubah sesuai dengan perubahan terakhir.',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              let data = row
              data.type = "update-reservasi-kajian-bbl-kembar"
              data.id_registrasi = this.$route.params.pageSlug
              data.p_kondisi_bayi = this.row.p_kondisi_bayi

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: data      
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mengupdate data pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.getDataForm()
                  }
                }).catch(err=>{ 
                  this.$swal.close()
                  this.loadingOverlay = false 
                  if(err){
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response.data.title
                      err.message = err.response.data.message
                      err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })    
              })
            }
          }) 
        }
      })
      
    },
    updateData(){
      this.$refs['VFormCatatanBBL'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda yakin untuk mengubah data pasien?',
            text: 'Data BBL di Dokter akan berubah sesuai dengan perubahan terakhir.',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: {
                    type: "update-reservasi-kajian-bbl",
                    id_registrasi: this.$route.params.pageSlug,
                    p_kondisi_bayi: this.row.p_kondisi_bayi,
                    bayi_hidup : this.row.lahir,
                    dokter: this.row.dokter,
                    jenis_ranap: this.row.jenis_ranap,
                    gender: this.row.gender,
                    berat_badan: this.row.berat_badan,
                    panjang_badan: this.row.panjang_badan,
                    lingkar_kepala: this.row.lingkar_kepala,
                    lingkar_dada: this.row.lingkar_dada,
                    nama_bayi: this.row.nama_bayi,
                    dob: this.row.dob,
                  }
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mengupdate data pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.getDataForm()
                  }
                })
              })
            }
          }).catch(err=>{ 
            this.$swal.close()
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response.data.title
                err.message = err.response.data.message
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })     
        }
      }) 
    }




  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getDataForm()
    },

    totalLamaPersalinan(v){
      this.$set(this.row, 'lama_persalinan_jam', v.hours)
      this.$set(this.row, 'lama_persalinan_menit', v.minutes)

      setTimeout(() => { 
        this.updateRow(this.row)
      }, 100)
    },
    
    catatanBBL: {
      handler(v) {
        this.autoSaveCatatanBBL(v)
      },
      deep: true
    },
  },

  computed: {
    Formatter() {
      return Formatter
    },

    MonitoringOptions() {
      return MonitoringOptions
    },
    totalLamaPersalinan(){
      const lamaKala1 = ( (this.row.lama_kala_1_jam||0) * 60) + (+this.row.lama_kala_1_menit||0)
      const lamaKala2 = ( (this.row.lama_kala_2_jam||0) * 60) + (+this.row.lama_kala_2_menit||0)
      const lamaKala3 = ( (this.row.lama_kala_3_jam||0) * 60) + (+this.row.lama_kala_3_menit||0)

      const totalJamKala = Math.floor((lamaKala1 + lamaKala2 + lamaKala3) / 60) * 60

      return {
        hours: Math.floor((lamaKala1 + lamaKala2 + lamaKala3) / 60),
        minutes: ((+lamaKala1 + +lamaKala2 + +lamaKala3) - (+totalJamKala)) || 0,
      }
    }
  }
}
</script>
